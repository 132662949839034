import $ = require("jquery");

window.onload = function(){

    $(".wrapper").append(`
    <div style="clear:both;"></div>
    `);

    $(".in-contents").append(`
    <div style="clear:both;"></div>
    `);

    $(".colmns").append(`
    <div style="clear:both;"></div>
    `);
}